<template>


  <div v-if="edited_week && props.diary && allowEdit">  
        
    <template v-if="isLoadedPresaver">
      <br>
      <br>
      <UiMessage
        type="green"
        :button-name="$t('create_week_view_presave_clear')"
        :button-type="'primary'"
        @click-button="presaveClear"
        >
        <b>{{ $t('create_week_view_loaded_from_previous_edit') }}</b>
        <div>
          <span>{{ $t('create_week_view_presave_text1') }}</span>
          {{ $t('create_week_view_presave_text2') }}
        </div>
      </UiMessage>
    </template>


    <WeekEditFazaGerm 
      v-if="edited_week.faza == -1" 
      :week="edited_week" 
      :diary="diary" 
      :is-loading-save="isLoadingSave"
      @updateweek="emits('updateweek')" 
      @saveweek="saveWeek($event)"
      @canceleditweek="emits('canceleditweek', $event)" 
      />      
    <WeekEditFazaVeg 
      v-if="edited_week.faza == 0" 
      :week="edited_week" 
      :diary="diary" 
      :is-loading-save="isLoadingSave"
      @update:faza="changeFazaVeg"
      @updateweek="emits('updateweek')" 
      @saveweek="saveWeek($event)"
      @canceleditweek="emits('canceleditweek', $event)" 
      />      
    <WeekEditFazaFlo 
      v-if="edited_week.faza == 1" 
      :week="edited_week" 
      :diary="diary" 
      :is-loading-save="isLoadingSave"
      @update:faza="changeFazaFlo" 
      @updateweek="emits('updateweek')" 
      @saveweek="saveWeek($event)"
      @canceleditweek="emits('canceleditweek', $event)" 
      />      
    <WeekEditFazaHar 
      v-if="edited_week.faza == 2" 
      :week="edited_week" 
      :diary="diary" 
      :is-loading-save="isLoadingSave"
      @updateweek="emits('updateweek')" 
      @saveweek="saveWeek($event)"
      @canceleditweek="emits('canceleditweek', $event)" 
      />         

  </div>

  <div v-else-if="props.diary?.id && !allowEdit">
    <UiNoAccess />
  </div>
</template>

<script setup>


const route = useRoute();
const { $api, $ga, $helper, $convert, $head, $popup, $vartpl, $presaver } = useNuxtApp();
const { t } = useI18n();
const link = ref(route.params['week']);
const edited_week = ref(null);
const original_week = ref(null);
const emits = defineEmits(['saveweek', 'updateweek', 'canceleditweek']);
const isLoadingSave = ref(false);
const isLoadedPresaver = ref(false);


const props = defineProps({
  week: {
    type: Object,  
  },
  diary: {
    type: Object,            
    required: true,
  }
});
const presaver = $presaver(props.week?.faza + ':' + props.week?.id, 'week');


const is_loading_photos = computed(() => {
  if(edited_week.value.items_photo)
    for (const [key, opt] of edited_week.value.items_photo.entries()) {
      if(opt.is_loading && !opt.is_remove)
        return true;
    }
  return false;
})


const convertUnitsForSave = (w) => {


  w.props.height = $convert.legitimed('length', useAuth().getLength(), w.props.height, 5);
  w.props.air_temp = $convert.legitimed('temperature', useAuth().getTemperature(), w.props.air_temp);
  w.props.ppm = $convert.legitimed('tds', useAuth().getTds(), w.props.ppm, 5);
  w.props.solution_temp = $convert.legitimed('temperature', useAuth().getTemperature(), w.props.solution_temp);
  w.props.substrate_temp = $convert.legitimed('temperature', useAuth().getTemperature(), w.props.substrate_temp);
  w.props.night_air_temp = $convert.legitimed('temperature', useAuth().getTemperature(), w.props.night_air_temp); 
  w.props.pot_size = $convert.legitimed('volume', useAuth().getVolume(), w.props.pot_size, 5);
  w.props.lamp_distance = $convert.legitimed('length', useAuth().getLength(), w.props.lamp_distance, 5);
  w.props.watering_volume = $convert.legitimed('volume', useAuth().getVolume(), w.props.watering_volume, 5);
  w.props.harvest_weight = $convert.legitimed('weight', useAuth().getWeight(), w.props.harvest_weight, 5);
  w.props.harvest_wet_weight = $convert.legitimed('weight', useAuth().getWeight(), w.props.harvest_wet_weight, 5); 
  w.props.harvest_space = $convert.legitimed('length', useAuth().getLength(), w.props.harvest_space, 5);
  
  if(w.faza == 0 || w.faza == 1){
    var wd = w.faza == 0 ? w.item_vegetation : w.item_flowering;

    if(wd.items_nutrient){
      wd.items_nutrient.map((item) => {
        console.log('item.unit-', item.unit, item.val);
        item.val = $convert.legitimed('nutrient', item.unit, item.val, 5);
        console.log('item.unit=', item.unit, item.val);
      });
    }

  }else if(w.faza == 2){
    var wd = w.item_harvest;
  }else if(w.faza == -1){
    var wd = w.item_germination;
    if(wd.items_nutrient){
      wd.items_nutrient.map((item) => {
        item.val = $convert.legitimed('nutrient', item.unit, item.val, 5);
      });
    }
  }


}

const convertUnitsForEdit = (w) => {

  // return false;

  if(w.faza == 0 || w.faza == 1){
    var wd = w.faza == 0 ? w.item_vegetation : w.item_flowering;
    w.props.height = w.props.height == 0 ? null :  $convert.universal('length', useAuth().getLength(), w.props.height);
    w.props.air_temp = w.props.air_temp == 0 ? null : $convert.universal('temperature', useAuth().getTemperature(), w.props.air_temp);
    w.props.ppm = w.props.ppm == 0 ? null : $convert.universal('tds', useAuth().getTds(), w.props.ppm);
    w.props.solution_temp = w.props.solution_temp == 0 ? null : $convert.universal('temperature', useAuth().getTemperature(), w.props.solution_temp);
    w.props.substrate_temp = w.props.substrate_temp == 0 ? null : $convert.universal('temperature', useAuth().getTemperature(), w.props.substrate_temp);
    w.props.night_air_temp = w.props.night_air_temp == 0 ? null : $convert.universal('temperature', useAuth().getTemperature(), w.props.night_air_temp); 
    w.props.pot_size = w.props.pot_size == 0 ? null : $convert.universal('volume', useAuth().getVolume(), w.props.pot_size);
    w.props.lamp_distance = w.props.lamp_distance == 0 ? null : $convert.universal('length', useAuth().getLength(), w.props.lamp_distance);
    w.props.watering_volume = w.props.watering_volume == 0 ? null : $convert.universal('volume', useAuth().getVolume(), w.props.watering_volume);

    if(wd.items_nutrient){
      wd.items_nutrient.map((item) => {
        item.val = $convert.universal('nutrient', item.unit, item.val);
      });
    }

  }else if(w.faza == 2){
    var wd = w.item_harvest;
    w.props.harvest_weight = $convert.universal('weight', useAuth().getWeight(), w.props.harvest_weight);
    w.props.harvest_wet_weight = $convert.universal('weight', useAuth().getWeight(), w.props.harvest_wet_weight); 
    w.props.harvest_space = $convert.universal('length', useAuth().getLength(), w.props.harvest_space);
  }else if(w.faza == -1){
    var wd = w.item_germination;
    if(wd.items_nutrient){
      wd.items_nutrient.map((item) => {
        item.val = $convert.universal('nutrient', item.unit, item.val);
      });
    }
  }



}

const fillPrevWeek = (needed_week, needed_week_data) => {
  

  if(!props.diary.items_week.filter((item) => item.faza == 0 || item.faza == 1).length)
    return false;

  var last_week = props.diary.items_week.filter((item) => item.faza == 0 || item.faza == 1).sort((a, b) => b.days - a.days)[0];
  if(!last_week)
    return false;

  var last_week_data = last_week.faza == 0 ? last_week.item_vegetation : last_week.item_flowering;
  if(!last_week_data)
    return false;


  needed_week.days = Number(last_week.days) + 1;

  if(last_week.props){
    needed_week.props = last_week.props;
  }

  if(last_week_data.items_nutrient){
    var last_nutrients = JSON.parse(JSON.stringify(last_week_data.items_nutrient));
    last_nutrients.map((item) => {
      item.ref_id = null;
    });

    needed_week_data.items_nutrient = last_nutrients;
  }
 
  convertUnitsForEdit(needed_week);
  
}

const prepareData = async () => {

  if(!(props.week?.id)){       

    original_week.value = JSON.parse(JSON.stringify($vartpl.week));

    if(link.value.substr(0,1) == 'g'){
      original_week.value.faza = -1;  
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();

      if(!original_week.value.props) {
        original_week.value.props = {};
      }
      original_week.value.props.germ_date = `${year}-${month}-${day}`;  
      original_week.value.item_germination.items_seed = props.diary.items_equip.filter(e => e.category == 'seed');

      original_week.value.item_germination.items_seed.map((item) => {
        item.cnt_germ = null;
      });
           
    }else if(link.value.substr(0,1) == 'v'){
      original_week.value.faza = 0;        
      fillPrevWeek(original_week.value, original_week.value.item_vegetation);  
    }else if(link.value.substr(0,1) == 'f'){
      original_week.value.faza = 1;      
      fillPrevWeek(original_week.value, original_week.value.item_flowering);  
    }else if(link.value.substr(0,1) == 'h'){
      original_week.value.faza = 2;       

      if(props.diary.items_week?.length){
        original_week.value.days = props.diary.items_week.filter((item) => item.faza == 0 || item.faza == 1).sort((a, b) => b.days - a.days)[0].days + 1;
      }

      var ref_id = link.value.substr(1);
      original_week.value.item_harvest.item_review_seed.ref_id = ref_id;
      original_week.value.item_harvest.item_review_seed.item_product = props.diary.items_equip.filter((item) => item.ref_id == ref_id)[0];
      original_week.value.item_harvest.item_review_seed.item_brand = original_week.value.item_harvest.item_review_seed.item_product.item_brand;
      //products
      if(edited_week_products.value){
        original_week.value.item_harvest.items_review = edited_week_products.value.items_review;
      }else{
        var products_data = await loadDataProducts(props.diary.id);
        original_week.value.item_harvest.items_review = products_data.items_review;
      }
      // //nutrients
      // if(edited_week_nutrients.value){
      //   original_week.value.item_harvest.items_nutrient = edited_week_nutrients.value.items_nutrient;
      // }else{
      //   var nutrients_data = await loadDataNutrients(props.diary.id);
      //   original_week.value.item_harvest.items_nutrient = nutrients_data.items_nutrient;
      // }
      // // lamps
      // if(edited_week_lamps.value){
      //   original_week.value.item_harvest.items_lamp = edited_week_lamps.value.items_lamp;
      // }else{
      //   var lamps_data = await loadDataLamps(props.diary.id);
      //   original_week.value.item_harvest.items_lamp = lamps_data.items_lamp;
      // }
      // // tents
      // if(edited_week_tents.value){
      //   original_week.value.item_harvest.items_tent = edited_week_tents.value.items_tent;
      // }else{
      //   var tents_data = await loadDataTents(props.diary.id);
      //   original_week.value.item_harvest.items_tent = tents_data.items_tent;
      // }
    } 
    presaver.setup(link.value + ':' + props.diary.id, 'week')
  }else{ 
    original_week.value = JSON.parse(JSON.stringify(props.week)); 

    convertUnitsForEdit(original_week.value);
    
    
  }

  console.log('prepare');
  console.log('original_week.value', original_week.value);
  console.log('edited_week.value', edited_week.value);

  if(presaver.exists() && false){
    isLoadedPresaver.value = true;
    edited_week.value = presaver.get();
    edited_week.value.is_presaved = true;
    return;
  }else{
    edited_week.value = JSON.parse(JSON.stringify(original_week.value));
  }

  console.log('edited_week.value', edited_week.value);
}

const loadDataProducts = async (id) => {
  if(props.week?.faza == 2){
    return {items_review: []};
  }
  let data = await $api.getDiaryHarvestProductsReview(id);  
  return data;
}
 


const presaveClear = function(){  
  isLoadedPresaver.value = false;
  edited_week.value = JSON.parse(JSON.stringify(original_week.value));
  console.log('presaveClear', edited_week.value, original_week.value);
  presaver.clearType();
}
 

const changeFazaVeg = (vl) => {
  edited_week.value = vl; 
  edited_week.value.item_flowering = vl.item_vegetation; 
  edited_week.value.item_vegetation = null; 
  edited_week.value.faza = 1; 
}

const changeFazaFlo = (vl) => {
  edited_week.value = vl; 
  edited_week.value.item_vegetation = vl.item_flowering; 
  edited_week.value.item_flowering = null; 
  edited_week.value.faza = 0; 
}


const validateWeek = (edited_week) => {  
  if(edited_week.faza == '-1'){

    if(!edited_week.items_photo?.length){
      $popup.error('Empty photos');
      return false;
    }


    if(edited_week.item_germination?.items_equip?.length){
      
      var emptyGermSeeds = edited_week.item_germination.items_equip.filter((item) => {
        if(item.category != 'seed') return false;

        if(item.cnt_germ === null){
          return item;
        }else{
          return false;
        }
      });

      if(emptyGermSeeds.length){
        $popup.error('Some of strain success field is empty');
        return false;
      }


    }

    if(!edited_week.text){
      $popup.error('Empty comment field');
      return false;
    }
  

  }else{

    if(!edited_week.days){
      $popup.error('Empty week number');
      return false;
    }

    if(edited_week.faza == 2){
      
      if(!edited_week.item_harvest.item_review_seed.n_general){
        $popup.error('Empty strain score');
        return false;
      }

      if(edited_week.item_harvest?.items_review?.length){

        var notRatedNutrients = edited_week.item_harvest.items_review.filter((item) => {
          var notRatedNut = item.items_review.filter((item) => !item.n_general);
          if(notRatedNut.length){
            return item;
          }else{
            return false;
          }
        });

        if(notRatedNutrients.length){
          $popup.error('Not all products are rated');
          return false;
        } 

      }



      if(edited_week.item_harvest?.items_lamp?.length){

        var notRatedLamps = edited_week.item_harvest.items_lamp.filter((item) => {
          var notRatedNut = item.items.filter((item) => !item.n_general);
          if(notRatedNut.length){
            return item;
          }else{
            return false;
          }
        });

        if(notRatedLamps.length){
          $popup.error('Not all lamps are rated');
          return false;
        } 

      }

      if(edited_week.item_harvest?.items_tent?.length){

        var notRatedTents = edited_week.item_harvest.items_tent.filter((item) => {
          var notRatedNut = item.items.filter((item) => !item.n_general);
          if(notRatedNut.length){
            return item;
          }else{
            return false;
          }
        });

        if(notRatedTents.length){
          $popup.error('Not all tents are rated');
          return false;
        } 

      }

    }


    if(edited_week.faza == 0 || edited_week.faza == 1){
      var duplicates = props.diary.items_week.filter((item) => {
        if(item.days == edited_week.days && item.id != edited_week.id && (item.faza == 0 || item.faza == 1)){
          return item;
        }
      });

      console.log('duplicates');
      console.log(duplicates);

      if(duplicates.length){
        $popup.error('Week with this number ' + edited_week.days + ' already exists');
        return false;
      }
    }

    if(!edited_week.items_photo?.length){
      $popup.error('Empty photos');
      return false;
    }
    if(!edited_week.text){
      $popup.error('Empty comment field');
      return false;
    }
  }
  return true;
} 



const allowEdit = computed(() => {
  if(props.diary.item_user.id == useAuth().getId())
    return true;
  return false;
});
 

const saveWeek = (edited_week) => {   
  
  
  if(!validateWeek(edited_week)) 
    return false;     

  if(is_loading_photos.value){
    $popup.error('Wait for photos to load');
    return false;     
  }

  isLoadingSave.value = true;


  var saving_data = JSON.parse(JSON.stringify(edited_week));

  convertUnitsForSave(saving_data);

  var form = saving_data;              
  if(!form.id){   
    $api.postWeek(props.diary.id, form) 
    .then((res) => {
      isLoadingSave.value = false;
      emits('updateweek', res.data.value.data.id);      
      presaver.clearType();        
    })
    .catch(function (error) { 
      // $popup.error(t('create_week_view_error_failed'));
      $popup.error('Error');
      isLoadingSave.value = false;
    });  
  }else{
    $api.putWeek(props.diary.id, form.id, form) 
    .then((res) => {      
      console.log('saved week id');      
      console.log(res.data.value.data);      
      emits('updateweek', res.data.value.data.id);    
      isLoadingSave.value = false; 
      presaver.clearType();        
    })
    .catch(function (error) { 
      // $popup.error(t('create_week_view_error_failed'));    
      $popup.error('Error');    
      isLoadingSave.value = false;
    });
  }
}



const { pending: is_loading_product,  data: edited_week_products } = await useLazyAsyncData('edited_week_product' + props.diary.id, async () => await loadDataProducts(props.diary.id));
// const { pending: is_loading_nutrient,  data: edited_week_nutrients } = await useLazyAsyncData('edited_week_nutrients', async () => await loadDataNutrients(props.diary.id));
// const { pending: is_loading_lamp,  data: edited_week_lamps } = await useLazyAsyncData('edited_week_lamps', async () => await loadDataLamps(props.diary.id));
// const { pending: is_loading_tent,  data: edited_week_tents } = await useLazyAsyncData('edited_week_tents', async () => await loadDataTents(props.diary.id));


console.log('[edit week]');

watch(() => edited_week, (new_edited_week, old_edited_week, ) => {
  // if(new_edited_week.value?.id) 

  var new_data = JSON.parse(JSON.stringify(new_edited_week?.value));

  if(new_data.item_vegetation){
    // if(useAuth().getTemperature() == 'c'){
      // new_data.item_vegetation.air_temp = $convert.legitimed('temperature', useAuth().getTemperature(), new_data.item_vegetation.air_temp);
    // }
  }

  if(JSON.stringify(new_data) != JSON.stringify(original_week?.value)){

    // if(new_data?.item_vegetation){
    //   console.log('new_data?.item_vegetation');
    //   console.log(new_data?.item_vegetation.air_temp);
      // new_data?.item_vegetation.air_temp = 0;//$convert.universal('temperature', 'f', new_edited_week?.value.item_vegetation.air_temp);
      // new_data?.item_vegetation.air_temp = new_data?.item_vegetation.air_temp;
    // }
    // new_data.faza = 0;
    
    
      console.log('new_data');
      console.log(new_data);

    presaver.set(new_data);
  }
  // console.log('new_edited_week');
  // console.log(new_edited_week);
}, {deep: true});



onMounted(() => {  
  prepareData();
});


onUnmounted(() => {  
  edited_week.value = null;
});




</script>

<style scoped>
 
</style>
